import React, {Component} from "react";

export default class Tom extends Component{
    componentDidMount(){
        const isBrowser = typeof window !== "undefined"
        if (!isBrowser) {
            return;
        }else{
            window.location.href = 'https://blog.stenograf.io/pl/autor/Tom-Horecki/'
        }
    }
    render(){
        return(<div/>)
    }
}